import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Provider from "../components/Provider"
import Header from "../components/Header/Header"

import styled, { css } from "../components/styled"
import getColumnSize from "../utils/getColumnSize"
import { Container } from "../components/UI/Grid/Grid"
import Link from "../components/UI/Link"
import {
  MainTitle,
  Description,
  Text,
  CounterTitle,
} from "../components/UI/Typography"
import Footer from "../components/Footer/Footer"

const TitleContent = styled(MainTitle)`
  position: relative;
  margin-bottom: 20px;
`

const TextContent = styled(Description)`
  margin-bottom: 40px;
  max-width: 405px;

  ${({ theme: { media } }) => media.above("large")} {
    margin-bottom: 60px;
  }
`

const Wrapper = styled.div`
  min-height: calc(100vh - 90px);
`

const Top = styled.div`
  padding: 32px ${getColumnSize(0)} 0;

  ${({ theme: { media } }) => media.above("large")} {
    padding: 100px ${getColumnSize(1)} 0;
  }
`

const Bottom = styled.div`
  width: ${getColumnSize(12)};
  padding: 40px 0 56px;

  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(11)};
    padding: 80px 0 90px ${getColumnSize(1)};
    display: flex;
  }
`

const LegalTopWrapper = styled.div`
  border-bottom: 1px solid
    ${({ theme: { colors } }) => `${colors.grayscale.mediumLight}30`};
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
`

const CompanyName = styled(Text)`
  margin-bottom: 32px;
  font-weight: 500;
`

const AddressGroup = styled.li`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled(CounterTitle)`
  margin-bottom: 5px;
`

const AddressText = styled(Text)`
  position: relative;
`

const LegalSidebar = styled.div`
  width: ${getColumnSize(12)};
  padding: 24px;
  background-color: ${({ theme: { colors } }) => colors.grayscale.white};
  border-radius: 8px;
  box-shadow: 0 3px 14px 0 rgba(83, 98, 124, 0.06);
  align-self: flex-start;
  margin-bottom: 56px;

  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(3)};
    margin-bottom: 0;
  }
`

const LegalContent = styled.div`
  width: ${getColumnSize(12)};
  margin-left: ${getColumnSize(0)};

  h2,
  h3,
  p,
  ul {
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 22px;
    margin-top: 40px;

    ${({ theme: { media } }) => media.above("large")} {
      margin-top: 0;
    }
  }

  p {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
    font-weight: 400;
  }

  ul {
    margin-bottom: 56px;
  }

  li {
    margin-bottom: 12px;
    position: relative;

    &:before {
      display: none;
      content: "-";
      position: absolute;
      left: -20px;
      top: 0;
      color: ${({ theme: { colors } }) => colors.blue.default};
      font-weight: 400;
    }
  }

  ${({ theme: { media } }) => media.above("large")} {
    width: ${getColumnSize(5)};
    margin-left: ${getColumnSize(1)};

    h2 {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.3px;
      line-height: 26px;
    }

    p {
      font-size: 16px;
      letter-spacing: -0.2px;
      line-height: 22px;
      font-weight: 400;
    }

    &:before {
      display: block;
    }
  }
`

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  max-width: 100vw;
  margin-left: -1.25em;
  margin-right: -1.25em;

  padding-left: 1.25em;
  padding-right: 1.25em;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${({ theme: { media } }) => css`
    ${media.above("small")} {
      overflow: visible;
      margin: 0;
      padding: 0;
    }
  `}
`

const NavigationColumn = styled.div`
  flex-wrap: nowrap;
  display: flex;
  white-space: nowrap;
`

interface TabItemProp {
  active?: boolean
  [key: string]: any
}

const TabItem = styled(Link)<TabItemProp>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  margin-bottom: -1px;
  margin-left: 2em;
  position: relative;

  padding-top: 0.6em;
  padding-bottom: 0.6em;

  border-bottom: 1px solid
    ${({ theme: { colors }, active }) =>
      active ? colors.blue.default : "transparent"};
  color: ${({ theme: { colors }, active }) =>
    active ? colors.blue.default : colors.grayscale.dark};

  &:first-child {
    margin-left: 0;
  }

  img {
    margin-top: -0.6em;
    margin-left: 0.4em;
    display: inline-block;
  }
`

interface TermsConditionsContent {
  markdownRemark: {
    html: string
  }
  contentYaml: {
    legal: {
      hero: {
        title: string
        copy: string
      }
      sidebar: {
        title: string
        items: Array<{ content: string; label: string }>
      }
    }
  }
}

const TermsConditions: React.FC = () => {
  const data: TermsConditionsContent = useStaticQuery(query)

  return (
    <Provider>
      <Header bgColor="#ffffff" />
      <Wrapper>
        <LegalTopWrapper>
          <Container>
            <Top>
              <TitleContent>{data.contentYaml.legal.hero.title}</TitleContent>
              <TextContent>{data.contentYaml.legal.hero.copy}</TextContent>

              <Navigation>
                <NavigationColumn>
                  <TabItem to="/privacy-policy">Privacy Policy</TabItem>
                  <TabItem to="/terms-conditions" active>
                    Terms & Conditions
                  </TabItem>
                </NavigationColumn>
              </Navigation>
            </Top>
          </Container>
        </LegalTopWrapper>
        <Container>
          <Bottom>
            <LegalSidebar>
              <CompanyName>{data.contentYaml.legal.sidebar.title}</CompanyName>

              <ul>
                {data.contentYaml.legal.sidebar.items.map(item => {
                  return (
                    <AddressGroup key={item.label}>
                      <Label color="mediumLight">{item.label}</Label>
                      <AddressText>{item.content}</AddressText>
                    </AddressGroup>
                  )
                })}
              </ul>
            </LegalSidebar>

            <LegalContent
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </Bottom>
        </Container>
      </Wrapper>
      <Footer />
    </Provider>
  )
}

export const query = graphql`
  query TermsConditions {
    markdownRemark(frontmatter: { id: { eq: "terms-conditions" } }) {
      html
    }
    contentYaml(legal: { hero: { copy: { ne: null } } }) {
      legal {
        hero {
          title
          copy
        }
        sidebar {
          items {
            content
            label
          }
          title
        }
      }
    }
  }
`

export default TermsConditions
